import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/moisturising/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/moisturising/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/moisturising/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/moisturising/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-banner-moisturising.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-moisturising-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> Moisturising
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Protezione idratante intensa</h3>
                      <p>
                        Se amate il sole e volete prendervi cura della vostra
                        pelle, la gamma di prodotti PIZ BUIN® MOISTURISING fa
                        per voi. I filtri solari avanzati UVA / UVB 
                        aiutano a proteggere la pelle e a
                        prevenirne l’invecchiamento precoce causato dal sole
                        grazie all’azione combinata di sostanze antiossidanti
                        efficaci. Questo unico complesso idratante dona
                        idratazione intensa per aiutare a mantenere il livello
                        di idratazione naturale della pelle durante
                        l’esposizione ai raggi solari.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="latte-solare" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LATTE SOLARE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          La formula non grassa e non appiccicosa del latte
                          solare PIZ BUIN<sup>®</sup> MOISTURISING dona
                          un'idratazione intensa durante l'esposizione al sole,
                          lasciando la pelle liscia e setosa per tutto il
                          giorno. Il latte solare PIZ BUIN<sup>®</sup>{" "}
                          MOISTURISING è resistente al sudore e all'acqua.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato, nuotato o essersi
                          asciugati. Evitare l'esposizione al sole nelle ore
                          centrali del giorno e per lungo tempo, anche se si
                          usano filtri solari. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Feverfew PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    La pianta di partenio, Feverfew, cresce sul
                                    suolo roccioso e nella boscaglia della
                                    Penisola Balcanica. La casa madre di PIZ
                                    BUIN®, Kenvue, ha brevettato
                                    questo estratto innovativo per la cura della
                                    pelle, applicando un nuovo processo di
                                    estrazione che trattiene gli ingredienti
                                    attivi ed elimina i componenti indesiderati
                                    che possono causare allergie.
                                  </p>
                                  <p>
                                    In che modo FEVERFEW PFE<sup>™</sup> aiuta
                                    la tua pelle?
                                  </p>
                                  <p>
                                    L'estratto vegetale Feverfew PFE<sup>™</sup>{" "}
                                    è un ingrediente lenitivo e antiossidante
                                    estremamente efficace. I test eseguiti in
                                    vitro dimostrano che produce un'attività di
                                    incremento degli antiossidanti maggiore
                                    rispetto ad altri estratti principali
                                    compresi salvia, tè verde, tè nero, Vitamina
                                    C e Vitamina E. Aiuta ad alleviare i rossori
                                    della pelle, ripara il DNA delle cellule
                                    della pelle danneggiato dal raggi UV e
                                    rafforza la resistenza delle cellule cutanee
                                    al sole*. La sua efficacia è stata
                                    comprovata da molti anni di approfondite
                                    ricerche, eseguite presso
                                    Kenvue, con numerosi brevetti
                                    che includono la sua applicazione nella cura
                                    della pelle e in campo cosmetico.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Derm Conversation Feb 2004 *Test in
                                    vitro
                                  </p>
                                  <p>Fig. 1</p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> protegge contro
                                    l'alterazione delle cellule, diminuisce gli
                                    eritemi indotti dai raggi UVB e consente di
                                    riparare il DNA delle cellule danneggiato
                                    dai raggi UV. Fino al 60% di miglioramento
                                    rispetto a eritemi e rossori indotti dai
                                    raggi UVB.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Pubblicazione Kenvue
                                    su Dermatol Res, feb 2008; 300 (2) 69-80,
                                    studio randomizzato in doppio cieco
                                    controllato con placebo effettuato su 12
                                    volontari.
                                  </p>
                                  <p>Fig. 2</p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> protegge contro le
                                    scottature del sole stimolando i naturali
                                    processi che mantengono l'integrità delle
                                    cellule della pelle* e rafforzando la loro
                                    resistenza al sole.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Kenvue R&amp;D,
                                    Metodo: applicazione di radiazioni UV su
                                    pelle umana espiantata e valutazione della
                                    scottatura causata dalle cellule
                                  </p>
                                  <p className="footNote">*Test in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="stick-solare-per-labbra" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>STICK SOLARE PER LABBRA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Lo stick solare per labbra PIZ BUIN<sup>®</sup>{" "}
                          protegge le labbra dagli effetti nocivi dei raggi
                          UVA/UVB grazie ad un'intensa idratazione. Lo stick
                          solare per labbra PIZ BUIN<sup>®</sup> è resistente
                          all'acqua.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor30a" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato, nuotato o essersi
                          asciugati. Evitare l'esposizione al sole nelle ore
                          centrali del giorno e per lungo tempo, anche se si
                          usano filtri solari. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Aloe Vera
                                <div className="textBlock bottom">
                                  <p>Cos’ha di buono l'Aloe Vera?</p>
                                  <p>
                                    L'Aloe Vera è un ricco idratante naturale
                                    dalle formidabili proprietà lenitive.
                                    Contiene circa 75 sostanze attive, tra cui
                                    11 minerali, 16 aminoacidi e 8 diverse
                                    vitamine.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="spray-solare-idratante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-ultra-light-sun-spray-30spf-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLARE IDRATANTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Grazie a filtri avanzati UVA/UVB, questo spray
                          garantisce una protezione immediata ed efficace.
                          Arricchito con * FEVERFEW PFE<sup>™</sup>, un potente
                          antiossidante, aiuta a prevenire l'invecchiamento
                          precoce della pelle causato dal sole. La formula ultra
                          leggera, non appiccicosa e non grassa garantisce un
                          assorbimento rapido senza lasciare tracce bianche
                          sulla pelle. Dona un'idratazione duratura che mantiene
                          la pelle liscia ed elastica durante l'esposizione al
                          sole.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Spray Solare
                            Idratante è resistente al sudore e all'acqua.
                          </p>
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Solare Idratante
                            ottiene il favore dei consumatori. Abbiamo chiesto
                            ai consumatori di dirci cosa ne pensano di PIZ BUIN
                            <sup>®</sup> ULTRA LIGHT Spray Solare Idratante**.
                          </p>
                          <p>
                            <strong className="orange">Il 100%</strong> ha
                            riscontrato:
                          </p>
                          <ul>
                            <li>un assorbimento immediato</li>
                            <li>una consistenza molto leggera</li>
                            <li>una consistenza non grassa</li>
                          </ul>
                          <p>
                            <strong className="orange">il 95%</strong> ha
                            riscontrato:
                          </p>
                          <ul>
                            <li>un’idratazione per tutto il giorno</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Leggi di piu´
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato, nuotato o essersi
                          asciugati. Evitare l'esposizione al sole nelle ore
                          centrali del giorno e per lungo tempo, anche se si
                          usano filtri solari. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                        <p className="footNote">* Test in vitro</p>
                        <p className="footNote">
                          ** Risultati ottenuti in base ad un test di
                          autovalutazione su Spray Solare SPF 30 applicato su 21
                          soggetti, almeno un'applicazione al giorno, per 1
                          settimana.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Feverfew PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    La pianta di partenio, Feverfew, cresce sul
                                    suolo roccioso e nella boscaglia della
                                    Penisola Balcanica. La casa madre di PIZ
                                    BUIN®, Kenvue, ha brevettato
                                    questo estratto innovativo per la cura della
                                    pelle, applicando un nuovo processo di
                                    estrazione che trattiene gli ingredienti
                                    attivi ed elimina i componenti indesiderati
                                    che possono causare allergie.
                                  </p>
                                  <p>
                                    In che modo FEVERFEW PFE<sup>™</sup> aiuta
                                    la tua pelle?
                                  </p>
                                  <p>
                                    L'estratto vegetale Feverfew PFE<sup>™</sup>{" "}
                                    è un ingrediente lenitivo e antiossidante
                                    estremamente efficace. I test eseguiti in
                                    vitro dimostrano che produce un'attività di
                                    incremento degli antiossidanti maggiore
                                    rispetto ad altri estratti principali
                                    compresi salvia, tè verde, tè nero, Vitamina
                                    C e Vitamina E. Aiuta ad alleviare i rossori
                                    della pelle, ripara il DNA delle cellule
                                    della pelle danneggiato dal raggi UV e
                                    rafforza la resistenza delle cellule cutanee
                                    al sole*. La sua efficacia è stata
                                    comprovata da molti anni di approfondite
                                    ricerche, eseguite presso
                                    Kenvue, con numerosi brevetti
                                    che includono la sua applicazione nella cura
                                    della pelle e in campo cosmetico.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Derm Conversation Feb 2004 *Test in
                                    vitro
                                  </p>
                                  <p>Fig. 1</p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> protegge contro
                                    l'alterazione delle cellule, diminuisce gli
                                    eritemi indotti dai raggi UVB e consente di
                                    riparare il DNA delle cellule danneggiato
                                    dai raggi UV. Fino al 60% di miglioramento
                                    rispetto a eritemi e rossori indotti dai
                                    raggi UVB.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Pubblicazione Kenvue
                                    su Dermatol Res, feb 2008; 300 (2) 69-80,
                                    studio randomizzato in doppio cieco
                                    controllato con placebo effettuato su 12
                                    volontari.
                                  </p>
                                  <p>Fig. 2</p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> protegge contro le
                                    scottature del sole stimolando i naturali
                                    processi che mantengono l'integrità delle
                                    cellule della pelle* e rafforzando la loro
                                    resistenza al sole.
                                  </p>
                                  <p className="footNote">
                                    Fonte: Kenvue R&amp;D,
                                    Metodo: applicazione di radiazioni UV su
                                    pelle umana espiantata e valutazione della
                                    scottatura causata dalle cellule
                                  </p>
                                  <p className="footNote">*Test in vitro</p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Glicerina
                                <div className="textBlock bottom">
                                  <p>
                                    La glicerina attira l’acqua e la trattiene
                                    nella pelle aiutandola a mantenere un buon
                                    livello di idratazione.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/it/prodotti/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
